body {
  margin: 0;
  font-size: 16px;
  line-height: 1.2;
  box-sizing: border-box;
  font-family: 'League Spartan', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 0 !important;
  overflow-y: scroll !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'VAG';
  src: url('./assets/fonts/VAG.ttf') format('truetype'); /* URL to font */
  font-weight: normal;
  font-style: normal;
}

body a.MuiLink-root{ 
  text-decoration: none;
  border-radius: 4px;
}

h1{
  font-family: 'VAG', 'League Spartan', Arial, sans-serif;
  font-size: 4rem;
  line-height: 1.24;
  margin: 0 0 1.2rem;
}

h2{
  text-align: center;
  font-family: 'VAG', 'League Spartan', Arial, sans-serif;
  font-size: 2.1rem;
  line-height: 1.24;
  margin: 0 0 1.2rem;
}
h3{
  font-size: 1.5rem;
  line-height: 1.24;
  margin: 0 0 1.2rem;
}
h4{
  font-size: 1.3rem;
  line-height: 1.24;
  margin: 0 0 1.2rem;
}
h5{
  font-size: 1.2rem;
  line-height: 1.24;
  margin: 0 0 1.2rem;
}

p{
  font-size: 16px;
  line-height: 1.24;
  margin: 0 0 1rem;
}
@media all and (max-width: 1499px){
  h1{
    font-size: 3rem;
  }
}

@media all and (max-width: 599px){
  h1{
    font-size: 2rem;
  }

}
/* Header Styles */
.site-header{
  height: 62px; /* To push content down - adjust for mobile*/
} 
.site-header .header-background {
  padding: 4px 0;
  color: white;
  background-color: #282828;
}
.MuiModal-backdrop{
  backdrop-filter: blur(4px);
}
.MuiBackdrop-invisible{
  backdrop-filter: none;
}
/* Review Bar Styles */
.review-bar {
  padding: 2px 0 0;
  height: 28px;
  background-color: white;
  border-bottom: 4px solid #B9E123;
}
.header-google-icon{
  margin: 2px 0 -4px 0;
}
.header-google-stars{
  margin: 2px 0px -2px 6px;
}
.review-text {
  display: inline-block;
  color: #282828;
  margin-left: 8px;
  line-height: 24px;
  font-weight: 700;
}
.find-us-text{
  display: inline-block;
  color: #282828;
  line-height: 24px;
  font-weight: 700;
}
.get-quote-header{
  min-width: 90px !important;
  white-space: nowrap;
}
.get-quote-footer{
  width: 146px !important;
  white-space: nowrap;
}
.call-us-now{
  color: white;
  font-size: 18px;
  line-height: 20px;
}
.call-us-phone{
  font-size: 26px;
  line-height: 30px;
}
/* Home Content */
.testimonial-google-icon{
  margin-right: 4px;
}

/* Form Content */

.desktop-date-picker{
  display: block !important;
  margin: 16px 0 0 !important;
  width: 100% !important;
  height: 3rem;
  border: 0 none;
  outline: none !important;
  cursor: pointer;
}
.desktop-date-picker > div, .mobile-date-picker > div{
  border-radius: 12px !important;
  border: 0 none !important;
  width: 100%;
}

.MuiPickersCalendarHeader-root .MuiSvgIcon-root {
  color: #3d3d3d; /* Set the SVG color to black */
}

@media all and (max-width: 899px){
  .desktop-date-picker{
    display: none !important;
  }
}
.desktop-date-picker:hover .MuiOutlinedInput-notchedOutline{
  border: 2px solid #B9E123;
}

.desktop-date-picker .MuiInputBase-input {
  color: black;
  height: 1.1rem;
}
.desktop-date-picker .MuiSvgIcon-root {
  fill: #858585;
}
.MuiDateCalendar-root{
  width: 360px !important;
}

.mobile-date-picker {
  display: none !important;
}
@media all and (max-width: 899px){
  .mobile-date-picker {
    display: block !important;
    position: relative !important;
    cursor: pointer;
    width: 100%;
    height: 3rem;
    margin: 16px 0 0;
    border: 0 none;
    outline: none !important;
  } 
  .mobile-date-picker .MuiInputBase-input {
    color: black;
    height: 1.1rem;
  }
  .MuiDateCalendar-root{
    width: 290px !important;
  }
}
@media all and (max-width: 349px){
  .mobile-date-picker .MuiInputBase-input {
    color: black;
    border: 0 none;
  }
}

/* Admin panel styles */

.MuiTooltip-popper .MuiTooltip-tooltip{
  padding: 10px 10px;  
  background: rgba(0,0,0,0.8);
}

/* Scrollable divs */

.scrollable-div {
  overflow-y: scroll;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: darkgrey #f1f1f1; /* For Firefox */
}

/* Chrome, Edge, and Safari */
.scrollable-div::-webkit-scrollbar {
  width: 6px;
}

.scrollable-div::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 12px;
}

.scrollable-div::-webkit-scrollbar-track {
  background: #F1F1F1;
  border-radius: 10px;
}

/* Footer Styles */
.call-us-phone-footer{
  display: inline-block;
  font-size: 26px; 
  line-height: 34px;
  height: 28px;
  font-weight: 700;
  color: #B9E123;
  padding: 5px 10px;
  margin: 0 0 24px -5px;
}
.call-us-phone-footer:hover{
  color: white;
  background: rgba(0,0,0,0.1);
  border-radius: 12px;
}

@media all and (max-width: 999px){
  .get-quote-header{
    display: none !important;
  }
  .call-us-phone{
    white-space: nowrap;
  }
}

@media all and (max-width: 899px){
  .call-us-phone-footer{
    margin-bottom: 40px;
  }
}

@media all and (max-width: 599px){
  .site-header{
    height: 70px; /* To push content down */
  }
  .review-text{
    color: #464646;
  }
  .review-text span{
    display: none;
  }
  .find-us-text{
    color: #464646;
  }
  .site-logo{
    width: 130px;
    height: 48px;
    margin: 4px 0;
  }
  .call-us-now{
    font-size: 16px;
    line-height: 18px;
  }
  .call-us-phone{
    font-size: 20px;
    line-height: 22px;  
  }

}
@media all and (max-width: 349px){
  .review-text{
    display: none;
  } 
  .call-us-now{
    font-size: 14px;
    line-height: 16px;
  }
  .call-us-phone{
    font-size: 18px;
    line-height: 22px;  
  }
}